/* The following CSS code adjusts the style when a user creates a pdf of/prints the page (ctrl+p) */
@media print {
  @page {
    /* print should be in landscape orientation, as that is the orientation of the site */
    size: landscape;
  }

  /* hides elements during print */
  .no-print,
  .no-print *,
  .MuiTooltip-popper {
    display: none !important;
  }

  .only-print,
  .only-print * {
    display: unset;
  }

  canvas,
  table {
    /* prevents the charts.js and material table components from overflowing */
    max-width: 100%;
    max-height: 50%;
    height: auto !important;
    width: auto !important;
  }

  #side-drawer {
    /* hides the sidedrawer, if it's open */
    display: none;
    width: 0px;
  }

  #App {
    /* ignores the space that is normally allocated for the side drawer */
    margin-left: 0px !important;
    /* keeps background colors visible in pdf view */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .print-break {
    /* adds a page break to the pdf */
    page-break-before: always;
  }

  .print-fullwidth {
    /* used to essentially keep the site using all of the pdf's width */
    max-width: 100% !important;
  }
}
