/* todo: combine this with App.css, and then delete this file */
.header-cell,
.data-cell {
  border: 1px solid #ddd;
  padding: 5px;
  /* Adjust the padding */
  text-align: center;
}

.header-cell {
  background-color: #f2f2f2;
  font-size: 8px;
  /* Adjust the font size for month and hour labels */
}

.data-cell {
  border: 1px solid #ddd;
  /* padding: auto; */
  /* Adjust the padding */
  /* text-align: center; */
  cursor: pointer;
  font-size: 8px;
  /* Adjust the font size */
  /* height: 20px; */
  /* Adjust the height */
  /* width: 20px; */
  /* Adjust the width */
  user-select: none;
  /* Prevent text selection */
}
